<template>
  <NuxtLink :to="to" :class="linkClasses" :target="target"> {{ text }} </NuxtLink>
</template>

<script setup lang="ts">
  const props = defineProps({
    text: {
      type: String,
      required: true,
    },
    to: String,
    external: Boolean,
  })

  const target = computed(() => (props.external ? '_blank' : '_self'))

  const linkClasses = 'hover:underline focus:underline inline-block lg:text-lg py-2'
</script>
