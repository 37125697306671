<template>
  <div class="text-white bg-gradient-midnight">
    <transition>
      <slot />
    </transition>
    <FooterNav />
    <button
      id="scroll-to-top-button"
      aria-label="Scroll to top"
      class="fixed p-2 lg:p-4 transition-quick rounded-full shadow-xl bottom-4 opacity-0 pointer-events-none right-4 md:bottom-16 md:right-16 bg-sky-blue-900 hover:bg-sky-blue-500"
      @click="scrollToTop && scrollToTop()"
      tabindex="-1">
      <HoapIcon icon="ChevronUp" class="w-8 h-8 text-white" />
    </button>
  </div>
</template>

<script setup lang="ts">
  const scrollToTopButton = computed(() => (process.client ? document.getElementById('scroll-to-top-button') : null))
  const scrollToTop = () => (process.client ? window.scrollTo({ top: 0, behavior: 'smooth' }) : null)

  const onScroll = () => {
    if (window.scrollY > 50) {
      scrollToTopButton.value?.classList.remove('opacity-0')
      scrollToTopButton.value?.classList.remove('pointer-events-none')
    } else {
      scrollToTopButton.value?.classList.add('opacity-0')
      scrollToTopButton.value?.classList.add('pointer-events-none')
    }
  }

  onMounted(() => {
    window.addEventListener('scroll', onScroll, { passive: true })
  })

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', onScroll)
  })
</script>
