<template>
  <footer class="bg-sky-blue-900">
    <div class="standard-spacing">
      <div class="md:flex w-full pt-8 md:pt-16 pb-8">
        <div class="w-full md:w-3/12 mb-8 md:mb-0">
          <NuxtLink to="/">
            <HoapIcon icon="HoapLogo" class="w-32 lg:w-48 text-white" />
          </NuxtLink>
        </div>
        <div class="w-full md:w-3/12 md:mr-8 mt-4 md:mt-0 mb-8 md:mb-0">
          <FooterNavMenu title="Sitemap" :links="sitemapLinks" />
        </div>
        <div class="w-full md:w-3/12 md:mr-8 mt-4 md:mt-0 mb-8 md:mb-0">
          <ClientOnly>
            <FooterNavMenu title="Socials" :links="socialLinks" />
          </ClientOnly>
        </div>
        <div class="w-full md:w-3/12 mt-4 md:mt-0">
          <FooterNavMenu title="Legal" :links="legalLinks" />
        </div>
      </div>
      <ClientOnly>
        <div v-if="acknowledgement" class="mb-4 text-xs md:text-sm">
          {{ acknowledgement }}
        </div>
      </ClientOnly>
      <div class="md:border-t border-white pb-8 md:py-8 md:flex justify-between text-xs md:text-sm">
        {{ copyrightText }}
        <div class="mt-4 md:mt-0">
          <ClientOnly>
            <span v-for="addressSection in addressSections" :key="addressSection.key">
              {{ addressSection.value + ' ' }}
            </span>
          </ClientOnly>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
  import { useQuery } from '@vue/apollo-composable'

  import sitemapLinks from '../sitemapLinks'
  import { GET_ADDRESS, GET_SOCIALS, GET_ACKNOWLEDGEMENT_OF_COUNTRY } from '../../queries'

  const { result: addressResult } = useQuery(GET_ADDRESS)
  const { result: socialsResult } = useQuery(GET_SOCIALS)
  const { result: acknowledgementResult } = useQuery(GET_ACKNOWLEDGEMENT_OF_COUNTRY)

  const legalLinks = [
    { text: 'Privacy Policy', url: '/privacy-policy' },
    { text: 'Terms of Service', url: '/terms-of-service' },
  ]

  const copyrightText = computed(() => '© Copyright ' + new Date().getFullYear() + '. All Rights Reserved.')
  const acknowledgement = computed(() => acknowledgementResult.value?.setting?.value ?? '')
  const addressSections = computed(() => addressResult.value?.settings ?? [])
  const socialLinks = computed(
    () =>
      socialsResult.value?.socials.map((social: { name: string; url: string }) => ({
        text: social.name,
        url: social.url,
        external: true,
      })) ?? []
  )
</script>
